import { useEffect, useState } from "react";
import classNames from "classnames";

export default function AnimatedSparkles({ className }: { className?: string }) {
  const [highlight, setHighlight] = useState<number | null>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const spark = () => {
      const i = Math.floor(Math.random() * 3);
      setHighlight(i);
      setTimeout(() => setHighlight(null), 500);

      const delay = Math.random() * 9000 + 1000; // 1s–10s
      timeout = setTimeout(spark, delay);
    };

    spark();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <svg
      className={classNames("w-6 h-6", className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {[0, 1, 2].map(i => (
        <g
          key={i}
          style={{
            transform: i === highlight ? "scale(1.28)" : "scale(1)",
            transition: "transform 0.3s ease-in-out",
            transformBox: "fill-box",
            transformOrigin: "center",
          }}
        >
          <path
            d={STAR_PATHS[i]}
            fill={i === highlight ? "#facc15" : "currentColor"}
            style={{ transition: "fill 0.3s ease-in-out" }}
          />
        </g>
      ))}
    </svg>
  );
}

const STAR_PATHS = [
  "M12 2 L13.2 6.2 L18 8 L13.2 9.8 L12 14 L10.8 9.8 L6 8 L10.8 6.2 Z",
  "M6 13 L7.5 16 L10.5 17 L7.5 18 L6 21 L4.5 18 L1.5 17 L4.5 16 Z",
  "M16 15 L17.5 17.5 L20.5 18 L17.5 18.5 L16 21 L14.5 18.5 L11.5 18 L14.5 17.5 Z",
];

